import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Advertisement from "@/components/Advertisement.vue";
import {
    apiSelfdriving,
    apicitys,
    apiscreen
} from "../../../../request/api.js";
export default {
    components: {
        Header,
        Footer,
        Advertisement
    }, //添加注册组件事件
    data() {
        return {
            issort: 0, //默认排序为第一项
            checkedcar: true, //控制车型的不限
            carcheckarr: [], //用于保存当前点击车型的数据
            carcheckListbox: [], //用于保存全部车型的数据

            checkedbrand: true, //控制品牌的不限
            brandcheckarr: [], //用于保存当前点击的品牌
            brandcheckListbox: [], //用于保存全部的品牌数据

            sortlist: [ //排序筛选数据
                {
                    Id: "",
                    Name: "综合排序"
                },
                {
                    Id: "ASC",
                    Name: "价格由低到高"
                },
                {
                    Id: "DESC",
                    Name: "价格由高到低"
                },
            ],

            isshowmore: true, //是否显示加载更多
            lastpage: 0, //总数量
            activecitynow: '', //当前点击的城市名称
            isnoData: false, //是否有数据
            isActiveCityohers: 2, //默认选择的是其他城市里面全部的北京数据
            isall: 1, //判断首字母全部是不是已经选中
            isActiveHotcity: 0, //关于热门城市的默认值
            isActiveOtherCity: 100000, //关于点击城市首字母
            isShowtop: false, //是否显示返回顶部
            scrollTop: 0, //滚动条的高度
            cityName: '北京', //默认展示的选择城市
            cityShow: false, //控制city的显示隐藏
            sortShow: false, //控制有关排序选项的隐藏显示
            screenShow: false, //控制有关筛选的隐藏显示
            slidervalue: [0, 0], //滑块的默认值
            checkList: ['复选框 A'],
            marks: {
                0: '0',
                500: '500',
                1000: '1000',
                2000: '不限'
            },
            //请求接口需要传递的参数
            cityId: 1, //城市id
            brandId: "", //品牌Id
            modelId: "", //车型Id
            priceRange: "", //价格区间
            priceSort: "", //排序 ASC升序 DESC降序
            page: 1, //默认请求列表第一页
            //有关请求数据之后返回数据保存
            hotCity: [], //热门城市列表
            OthersCityWord: [], //城市列表首字母,
            OthersCity: [], //城市列表,
            //车列表数据
            carList: [], //车数据
            allcity: [], //全部城市数据（未处理的数据）
            acity: [], //用于筛选里面全部城市的数据

            /*关于页面的图片以及标题区域*/
            bannerImg: '', //图片
            plateSubTit: '', //副标题
            plateTit: '' //标题
        }
    },
    created() {
        this.getCarlist()
        this.Selfdriving()
        this.getscreen()
    },
    mounted() {

    },
    methods: {
        getCarlist() {
            //请求有关车列表的数据
            apiSelfdriving({
                cityId: this.cityId,
                brandId: this.brandId,
                modelId: this.modelId,
                priceRange: this.priceRange,
                priceSort: this.priceSort,
                page: this.page,
                pageSize: 20,
            }).then(res => {
                if (res.data.code == 200) {
                    if (res.data.data.list.data.length > 0) {
                        this.isnoData = false;
                        let arr = res.data.data.list.data
                        this.carList = this.carList.concat(arr)
                        this.lastpage = res.data.data.list.last_page;
                        //判断是否展示加载更多按钮
                        //总数据
                        let zonglist = res.data.data.list.total;
                        //如果当前的数据大于或者等于总数据那么需要隐藏加载更多按钮
                        if (this.carList.length >= zonglist) {
                            this.isshowmore = false;
                        } else {
                            this.isshowmore = true;
                        }
                    } else {
                        this.isnoData = true;
                        this.isshowmore = false;
                        this.carList = [];
                    }
                    //返回标题以及图片
                    this.plateTit = res.data.data.plateTit;
                    this.plateSubTit = res.data.data.plateSubTit;
                    this.bannerImg = res.data.data.plateImage;
                }
            });
        },
        Selfdriving() {
            //请求城市筛选的数据
            apicitys({}).then(res => {
                if (res.data.code == 200) {
                    //热门城市区域
                    this.hotCity = res.data.data.hotCitys;
                    //其他城市区域
                    let citylist = [];
                    let cityzm = [];
                    let arrresult = res.data.data.citys
                    for (var item of arrresult) {
                        cityzm.push(item.key)
                        for (var item1 of item.list) {
                            citylist.push(item1)
                        }
                    }
                    this.OthersCityWord = cityzm.sort()
                    this.OthersCity = citylist;
                    this.acity = citylist;
                    this.allcity = arrresult;
                }
            });
        },
        getscreen() {
            //获取有关筛选数据
            apiscreen({}).then(res => {
                if (res.data.code == 200) {
                    this.brandcheckListbox = res.data.data.brand;
                    this.carcheckListbox = res.data.data.model;
                }
            });
        },
        formatTooltip(val) {
            return val / 100;
        },
        //关于切换方法
        changescreen(type) {
            if (type == 1) { //表示点击的是城市选择器
                this.cityShow = !this.cityShow;
                if (this.screenShow) {
                    this.screenShow = false;
                }
                if (this.sortShow) {
                    this.sortShow = false;
                }
            } else if (type == 2) {
                this.screenShow = !this.screenShow;
                if (this.cityShow) {
                    this.cityShow = false;
                }
                if (this.sortShow) {
                    this.sortShow = false;
                }
            } else if (type == 3) {
                this.sortShow = !this.sortShow;
                if (this.cityShow) {
                    this.cityShow = false;
                }
                if (this.screenShow) {
                    this.screenShow = false;
                }
            }
        },
        // 点击热门城市
        changeHotcity(id, cityname) {
            this.cityShow = false;
            this.cityId = id;
            this.cityName = cityname;
            this.page = 1; //点击热门城市需要充值page
            this.carList = []; //点击热门城市需要先清空数据
            this.getCarlist();
            this.activecitynow = "";
        },
        //点击城市字母表
        changeOtherCity(index, item) {
            this.isActiveOtherCity = index;
            this.isall = 0;
            //点击首字母显示对应的城市数据
            for (var item1 of this.allcity) {
                if (item == item1.key) {
                    this.OthersCity = item1.list
                }
            }
        },
        //点击城市名称
        changecityothers(id, name) {
            this.activecitynow = name;
            this.cityId = id;
            this.cityName = name;
            this.cityShow = false;
            this.page = 1; //点击热门城市需要充值page
            this.carList = []; //点击城市名称需要先清空数据
            this.getCarlist();
        },
        //点击首字母全部
        activeAll() {
            this.isall = 1;
            this.isActiveOtherCity = 6666;
            this.OthersCity = this.acity;
        },
        //车列表跳转到详情
        Todetailcar(id) {
            window.location.href = this.FunPublic.url + "carDetails.html?" + "id=" + escape(id)
        },
        //加载更多数据
        morelist() {
            this.page++;
            this.getCarlist();
        },
        //品牌选择
        isbrandactive() {
            if (this.brandcheckarr != []) {
                this.checkedbrand = false;
            }
        },
        //品牌不限方法
        isbrandbuxianactive(event) {
            this.checkedbrand = event;
            if (this.checkedbrand) {
                this.brandcheckarr = [];
            }
        },
        //车型选择方法
        iscarctive() {
            if (this.carcheckarr != []) {
                this.checkedcar = false;
            }
        },
        //车型不限方法
        iscarbuxianlist(event) {
            this.checkedcar = event;
            if (this.checkedcar) {
                this.carcheckarr = [];
            }
        },
        //滑块滚动事件
        slideValue() {

        },
        //重置事件
        resultForm() {
            this.page = 1;
            this.checkedcar = true;
            this.checkedbrand = true;
            this.brandcheckarr = [];
            this.carcheckarr = [];
            this.slidervalue = [0, 0];
            //点击重置清除筛选表单然后进行请求数据
            this.SubmitForm();
        },
        //提交事件
        SubmitForm() {
            //修改数据
            let brandIdnow = ""; //品牌
            let modelIdnow = ""; // 车型
            let priceRangenow = ""; //价格           
            if (this.checkedcar) { //车型
                modelIdnow = "";
            } else {
                modelIdnow = (this.carcheckarr).toString();
            }
            //品牌
            if (this.checkedbrand) {
                brandIdnow = "";
            } else {
                brandIdnow = (this.brandcheckarr).toString();
            }
            //价格
            if (this.slidervalue[1] == 2000 && this.slidervalue[0] != 0) { //表示选择的是不限 初始值为500或者1000
                let fristnum = this.slidervalue[0].toString();
                priceRangenow = `${fristnum},99999`
            } else if (this.slidervalue[0] == 0 && this.slidervalue[1] == 2000) { //表示选择的不限 初始值为0
                priceRangenow = "";
            } else if (this.slidervalue[0] == 0 && this.slidervalue[1] == 0) { //表示选择的是0
                priceRangenow = "";
            } else {
                priceRangenow = (this.slidervalue).toString();
            }
            //请求接口数据
            this.brandId = brandIdnow
            this.modelId = modelIdnow
            this.priceRange = priceRangenow
            this.carList = [];
            this.getCarlist();
            this.screenShow = false;
        },
        //排序方式点击事件
        changesort(index, id) {
            this.issort = index;
            this.priceSort = id;
            this.sortShow = false;
            this.page = 1;
            this.carList = [];
            this.getCarlist();
        }
    },
};